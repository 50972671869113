<template>
    <div class='dashboard'>
        <div class="col-12 md:col-6 lg:col-4 task-list">
            <ul>
                <li v-for='role in roleValueList' :key='role.key'>
                    <div class='flex'>
                        <span class="task-name col-6 font-bold">{{ role.value }}</span>
                        <Checkbox :value="role.key"  v-model='roleValue' class='ml-2'/>
                        <RadioButton :value='role.key' v-model='defaultRoleValue' class='ml-2'></RadioButton>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import { computed } from 'vue';
import axios from 'axios';

export default {
    name: 'RolePicker',
    emits: ['roleValueChange', 'defaultRoleChange'],
    setup() {
        const store = useStore()
        const primeVue = usePrimeVue()

        return {
            token: computed(() => store.state.bearerToken),
            t: primeVue.config.locale,
        }
    },
    data() {
        return {
            roleValueList: null,
            roleValue: [],
            defaultRoleValue: null,
        }
    },
    props: {
        propDefaultRoleValue: {
            type: String,
            required: false,
            default: null,
        },
        propRoleValue: {
            type: Array,
            required: false,
            default: null,
        }
    },
    watch: {
        roleValue:function() {
            this.$emit('roleValueChange', this.roleValue)
        },
        defaultRoleValue:function() {
            this.checkDefaultRoleValue(this.defaultRoleValue)
            this.$emit('defaultRoleChange', this.defaultRoleValue)
            this.$emit('roleValueChange', this.roleValue)
        }
    },
    created() {

    },
    mounted() {
        this.getRoleValueList()
        setTimeout(() => {
            if(this.propDefaultRoleValue) {
                this.defaultRoleValue = this.propDefaultRoleValue
                this.roleValue = this.propRoleValue
            }
        }, 300)
    },
    methods: {
        getRoleValueList() {
            axios
                .get( '/app/webObjects/dropdown/ROLES/VALUE/false/get', {
                    headers: {
                        Authorization: this.token
                    }})
                .then( response => {
                    if(response.status === 200 && response.data.rmsList[0].retCode === 0){
                        this.roleValueList = response.data.retObj.dropDownList;
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg, life: 3000});
                    }
                })
                .catch(error => {
                    console.log("There was an error loading users list!");
                    console.log(error);
                })
        },
        checkDefaultRoleValue(newRole) {
          if(!this.roleValue.includes(newRole)) {
            this.roleValue.push(newRole)
          }
        }
    }
};
</script>

<style scoped>

</style>