<template>
	<div class="layout-footer">
		<span class="footer-text-left">PrimeVue Apollo</span>
		<span class="footer-text-right">All Rights Reserved</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>
