<template>
    <div class="login-body">
        <div class="body-container">
            <div class="grid">
                <div class="col-12 lg:col-6 left-side">
                    <img src="layout/images/ca-logo.png" alt="apollo-layout" class="logo"/>
                    <h1>Welcome</h1>
                    <p>
                        Set your password.
                    </p>
                </div>
                <div class="col-12 lg:col-6 right-side">
                    <div class="login-wrapper">
                        <div class="login-container">
                            <span class="title">Set Password</span>
                            <Toast />
                            <div class="grid p-fluid">
                                <div class="col-12 text-left">
                                    <InputText placeholder="Code" v-model="code" v-bind:class="{ 'p-error' : invalidCode }" aria-describedby="username-help"/>
                                    <small id="username-help" class="p-error" v-if="invalidCode">{{ invalidCodeMessage }}</small>
                                </div>
                                <div class="col-12 text-left">
                                    <InputText type="password" placeholder="Password" v-model="password" v-bind:class="{ 'p-error' : invalidPassword }" aria-describedby="password-help"
                                               v-tooltip="'Minimum password length is 6 characters. \n Password must include upper and lower case characters. \n Password must include at least one number and one special character.'"/>
                                    <small id="password-help" class="p-error" v-if="invalidPassword">{{ invalidPasswordMessage }}</small>
                                </div>
                                <div class="col-12 text-left">
                                    <InputText type="password" placeholder="Repeat password" v-model="password2" v-bind:class="{ 'p-error' : invalidPassword2 }" aria-describedby="password-help2"
                                               v-tooltip="'Password and repeat password must match.'"/>
                                    <small id="password-help2" class="p-error" v-if="invalidPassword2">{{ invalidPasswordMessage2 }}</small>
                                </div>
                                <div class="col-6">
                                    <Button label="Set new password" icon="pi pi-check" @click="verifyInputs"/>
                                </div>
                                <div class="col-6 password-container">
                                    <Button class="p-button-link" @click="$router.push('/login')">Login</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'SetPassword',
    data() {
        return {
            code: "",
            password: "",
            password2: "",
            validInputs: true,
            invalidCode: false,
            invalidCodeMessage: "",
            invalidPassword: false,
            invalidPasswordMessage: "",
            invalidPassword2: false,
            invalidPasswordMessage2: "",
            message: []
        }
    },
    created() {

    },
    mounted() {
        let code = null;
        code = this.$route.query.t;

        if(code) {
            this.code = code;
        }

    },
    methods: {
        verifyInputs() {
            this.message = []
            this.validInputs = true
            if(!this.code) {
                this.invalidCodeMessage = "//Code cannot be empty."
                this.invalidCode = true
                this.validInputs = false
            } else {
                this.invalidCode = false
            }

            if(!this.password) {
                this.invalidPasswordMessage = "//Password cannot be empty."
                this.invalidPassword = true
                this.validInputs = false
            } else {
                this.invalidPassword = false
            }

            if(!this.password2) {
                this.invalidPasswordMessage2 = "//Password cannot be empty."
                this.invalidPassword2 = true
                this.validInputs = false
            } else {
                this.invalidPassword2 = false
            }

            let passwordMatch = (this.password === this.password2)

            if(!passwordMatch) {
              this.invalidPasswordMessage2 = "//Passwords do not match."
              this.invalidPassword2 = true
              this.invalidPasswordMessage = "//Passwords do not match."
              this.invalidPassword = true
              this.validInputs = false
            } else {
              this.invalidPassword = false
              this.invalidPassword2 = false
            }

            if(this.validInputs) {
                this.setPassword()
            }
        },
        setPassword() {
            axios
                .post( '/api/public/user/setpassword', {
                    'token': this.code,
                    'password': this.password
                })
                .then( response => {
                    //console.log(response.data.retObj);
                    if(response.data.rmsList[0].retCode === 410){
                        this.$toast.add({severity:'success', summary: 'Success', detail:response.data.rmsList[0].retMsg});
                        setTimeout(() => {
                            this.code = ""
                            this.password = ""
                            this.password2 = ""
                            this.$router.push('/login')
                        }, 5000)
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg});
                    }
                })
                .catch(error => {
                    console.log("There was an error loading users list!");
                    console.log(error);
                })
        }
    }
};
</script>

<style scoped>

</style>