import { createStore } from 'vuex';
import router from '@/router';
import axios from 'axios';

export const store = createStore({
    state: {
        bearerToken: null,
        userId: null,
        langDict: null,
        defLang: null
    },
    mutations: {
        authUser (state, userData) {
            state.bearerToken = userData.bearerToken
            state.userId = userData.userId
        },
        logout (state) {
            state.bearerToken = null
            state.userId = null
            state.langDict = null
            state.defLang = null
        },
        setLangDict (state, langDict) {
            state.langDict = langDict
        },
        setDefLang (state, deflang) {
            state.defLang = deflang
        }
    },
    actions: {
        setLogoutTimer ({dispatch}, expirationTime) {
            setTimeout(() => { dispatch('logout')
            }, expirationTime)
        },
        login ({commit, dispatch}, authData) {
            //console.log(authData.username, authData.password)
            axios.post('/login', {
                username: authData.username,
                password: authData.password
            })
                .then(res => {
                    commit('authUser', {
                        bearerToken: res.headers.authorization,
                        userId: res.data.userId
                    })
                    const now = new Date()
                    const expirationDate = new Date(now.getTime() + 3600*1000*240)
                    localStorage.setItem('bearerToken', res.headers.authorization)
                    localStorage.setItem('userId', res.data.userID)
                    localStorage.setItem('expirationDate', expirationDate)
                    localStorage.setItem('lang', res.data.defLang)

                    dispatch('languageSet')
                    dispatch('defLanguageSet')

                    if(res.status === 200) {
                        router.push('/');
                        dispatch('setLogoutTimer', 3600*1000*240)
                    }
                })
                .catch(error  => {
                    console.log(error)
                })
        },
        tryAutoLogin ({commit, dispatch}) {
            let bearerToken =  localStorage.getItem('bearerToken')
            //TODO: Check if token is valid

            if (!bearerToken) {
                router.replace('/login');
                return
            }

            const expirationDate = localStorage.getItem('expirationDate')
            const now = new Date()
            if (now >= expirationDate) {
                router.replace('/login');
                return
            }

            dispatch('setLogoutTimer', 3600*1000*240)

            const userId = localStorage.getItem('userId')
            commit('authUser', {
                bearerToken: bearerToken,
                userId: userId
            })

            dispatch('defLanguageSet')
        },
        logout ({commit}){
            commit('logout')
            localStorage.removeItem('bearerToken')
            localStorage.removeItem('userId')
            localStorage.removeItem('expirationDate')
            localStorage.removeItem('userMenu')
            localStorage.removeItem('lang')
            localStorage.removeItem('langDict')
            localStorage.removeItem('appDefLang')

            router.replace('/login')
        },
        languageSet ({commit}){
            //get language of a user from localstorage
            // TODO: Check if language was changed if yes then straight to API
            let lang = localStorage.getItem('lang')
            let langDict = JSON.parse(localStorage.getItem('langDict'))

            if(langDict) {
                commit('setLangDict', {
                    langDict: langDict
                })
            } else {
                //get language dictionary from API
                axios.get('/api/public/dictionary/'+ lang +'/get', {
                })
                    .then( res => {
                        if(res.status === 200) {
                            if(res.data.rmsList[0].retCode === 0) {
                                let langDict = res.data.retObj.dictionary
                                langDict['dayNames'] = res.data.retObj.dayNames
                                langDict['dayNamesMin'] = res.data.retObj.dayNamesMin
                                langDict['dayNamesShort'] = res.data.retObj.dayNamesShort
                                langDict['monthNames'] = res.data.retObj.monthNames
                                langDict['monthNamesShort'] = res.data.retObj.monthNamesShort
                                langDict['firstDayOfWeek'] = res.data.retObj.firstDayOfWeek
                                //
                                localStorage.setItem('langDict', JSON.stringify(langDict))

                                commit('setLangDict', {
                                    langDict: langDict
                                })
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        defLanguageSet({commit}) {
            let defLang = localStorage.getItem('appDefLang')

            if(defLang) {
                commit('setDefLang', {
                    defLang: defLang
                })
            }else{
                //set default app language
                axios.get('/api/public/parameter/defaults/get', {
                })
                    .then( res => {
                        if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
                            let defLang = res.data.retObj.LANGUAGE

                            localStorage.setItem('appDefLang', defLang)

                            commit('setDefLang', {
                                defLang: defLang
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
    },
    getters: {

    }
})