<template>
<div class='card'>
  <div @click='showFilter = !showFilter' class='mb-0' style='cursor: pointer'>
    <div class='hr-line-dashed-bottom flex justify-content-between mb-2'>
      <h4 v-if='title' class='font-bold mb-0'>{{ title }}</h4>
      <i :class="{'mr-4:': true, 'pi pi-minus': showFilter, 'pi pi-plus': !showFilter}"></i>
    </div>
  </div>
  <transition v-if='showFilter'>
    <slot name='filterbody'></slot>
  </transition>
  <transition v-if='showFilter'>
    <slot name='filterfooter'></slot>
  </transition>
</div>
</template>

<script>
export default {
  name: 'FoldableCard',
  props: {
    title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      showFilter: true,
    }
  }
};
</script>

<style scoped lang='scss'>

.hr-line-dashed-bottom {
  border-bottom: 1px dashed lightgray;
}

.hr-line-bottom {
  border-bottom: 1px solid lightgray;
}

</style>