<template>
	<div class="exception-body notfound-body">
		<div class="exception-container">
			<img :src="'layout/images/logo-' + logoColor + '.png'" alt="apollo-layout"/>
			<h1>Not Found</h1>
			<p>
				Resource is not found.
			</p>

			<router-link to="/">
				<Button label="Go To Dashboard" icon="pi pi-arrow-left" />
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		computed: {
			logoColor() {
				if (this.$appState.colorScheme === 'light')
					return 'dark';
				else return 'white';
			}
		}
	}
</script>

<style scoped>

</style>