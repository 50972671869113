import {createRouter, createWebHistory} from 'vue-router';  // CAIS SPECIFIC changed from createWebHashHistory to createWebHistory

const routes = [
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('./components/Dashboard.vue')
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
        },
    },
    // {
    //     path: '/invalidstate',
    //     name: 'invalidstate',
    //     component: () => import('./components/InvalidStateDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
    //     },
    // },
    // {
    //     path: '/input',
    //     name: 'input',
    //     component: () => import('./components/InputDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
    //     },
    // },
    // {
    //     path: '/floatlabel',
    //     name: 'floatlabel',
    //     component: () => import('./components/FloatLabelDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
    //     },
    // },
    // {
    //     path: '/button',
    //     name: 'button',
    //     component: () => import('./components/ButtonDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
    //     },
    // },
    // {
    //     path: '/table',
    //     name: 'table',
    //     component: () => import('./components/TableDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
    //     },
    // },
    // {
    //     path: '/list',
    //     name: 'list',
    //     component: () => import('./components/ListDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
    //     },
    // },
    // {
    //     path: '/tree',
    //     name: 'tree',
    //     component: () => import('./components/TreeDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
    //     },
    // },
    // {
    //     path: '/panel',
    //     name: 'panel',
    //     component: () => import('./components/PanelsDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }],
    //     },
    // },
    // {
    //     path: '/overlay',
    //     name: 'overlay',
    //     component: () => import('./components/OverlayDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
    //     },
    // },
    // {
    //     path: '/media',
    //     name: 'media',
    //     component: () => import('./components/MediaDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
    //     },
    // },
    {
        path: '/menu',
        component: () => import('./components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
        ],
    },
    // {
    //     path: '/messages',
    //     name: 'messages',
    //     component: () => import('./components/MessagesDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
    //     },
    // },
    // {
    //     path: '/file',
    //     name: 'file',
    //     component: () => import('./components/FileDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
    //     },
    // },
    // {
    //     path: '/chart',
    //     name: 'chart',
    //     component: () => import('./components/ChartDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Chart' }],
    //     },
    // },
    // {
    //     path: '/misc',
    //     name: 'misc',
    //     component: () => import('./components/MiscDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
    //     },
    // },
    // {
    //     path: '/icons',
    //     name: 'icons',
    //     component: () => import('./utilities/Icons.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
    //     },
    // },
    // {
    //     path: '/widgets',
    //     name: 'widgets',
    //     component: () => import('./utilities/Widgets.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
    //     },
    // },
    // {
    //     path: '/grid',
    //     name: 'grid',
    //     component: () => import('./utilities/GridDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
    //     },
    // },
    // {
    //     path: '/spacing',
    //     name: 'spacing',
    //     component: () => import('./utilities/SpacingDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
    //     },
    // },
    // {
    //     path: '/elevation',
    //     name: 'elevation',
    //     component: () => import('./utilities/ElevationDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
    //     },
    // },
    // {
    //     path: '/typography',
    //     name: 'typography',
    //     component: () => import('./utilities/Typography.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
    //     },
    // },
    // {
    //     path: '/display',
    //     name: 'display',
    //     component: () => import('./utilities/DisplayDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
    //     },
    // },
    // {
    //     path: '/flexbox',
    //     name: 'flexbox',
    //     component: () => import('./utilities/FlexBoxDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
    //     },
    // },
    // {
    //     path: '/text',
    //     name: 'text',
    //     component: () => import('./utilities/TextDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
    //     },
    // },
    // {
    //     path: '/crud',
    //     name: 'crud',
    //     component: () => import('./pages/CrudDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Pages', label: 'Crud' }],
    //     },
    // },
    // {
    //     path: '/calendar',
    //     name: 'calendar',
    //     component: () => import('./pages/CalendarDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Pages', label: 'Calendar' }],
    //     },
    // },
    // {
    //     path: '/timeline',
    //     name: 'timeline',
    //     component: () => import('./pages/TimelineDemo.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
    //     },
    // },
    // {
    //     path: '/invoice',
    //     name: 'invoice',
    //     component: () => import('./pages/Invoice.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
    //     },
    // },
    // {
    //     path: '/help',
    //     name: 'help',
    //     component: () => import('./pages/Help.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Pages', label: 'Help' }],
    //     },
    // },
    // {
    //     path: '/empty',
    //     name: 'empty',
    //     component: () => import('./components/EmptyPage.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
    //     },
    // },
    // {
    //     path: '/documentation',
    //     name: 'documentation',
    //     component: () => import('./components/Documentation.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Start', label: 'Documentation' }],
    //     },
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    }
    // ,
    // {
    //     path: '/error',
    //     name: 'error',
    //     component: () => import('./pages/Error.vue')
    // },
    // {
    //     path: '/notfound',
    //     name: 'notfound',
    //     component: () => import('./pages/NotFound.vue')
    // },
    // {
    //     path: '/access',
    //     name: 'access',
    //     component: () => import('./pages/Access.vue')
    // },
    // {
    //     path: '/blocks',
    //     name: 'blocks',
    //     component: () => import('./components/BlocksDemo.vue')
    // }
    // CAIS SPECIFIC - START
    ,
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('./pages/ForgotPassword.vue')
    },
    {
        path: '/setPassword',
        name: 'setPassword',
        component: () => import('./pages/SetPassword.vue')
    },
    // Languages / Dictionary
    {
        path: '/languages',
        name: 'languages',
        component: () => import('./components/Internationalization/Languages.vue'),
    },
    {
        path: '/texts/:languageParameter',
        name: 'texts',
        component: () => import('./components/Internationalization/Texts.vue'),
    },
    // Parameters
    {
        path: '/paramTables',
        name: 'parameterTables',
        component: () => import('./components/Parameters/Tables.vue'),
    },
    {
        path: '/editParamTable/:parameterTableId',
        name: 'editParameterTable',
        component: () => import('./components/Parameters/EditTables.vue'),
    },
    {
        path: '/paramModels',
        name: 'parameterModels',
        component: () => import('./components/Parameters/Models.vue'),
    },
    {
        path: '/editParamModel/tid/:tid/mid/:mid',
        name: 'editModel',
        component: () => import('./components/Parameters/EditModel.vue'),
    },
    {
        path: '/editParameter/tid/:tid/mid/:mid/rid/:rid',
        name: 'editParameter',
        component: () => import('./components/Parameters/EditParameter.vue'),
    },
    {
        path: '/addParameter/tid/:tid',
        name: 'addParameter',
        component: () => import('./components/Parameters/AddParameter.vue'),
    },
    // User / Roles / ...
    {
        path: '/roles',
        name: 'roles',
        component: () => import('./components/Users/Roles.vue')
    },
    {
        path: '/editrole/:roleName',
        name: 'editrole',
        component: () => import('./components/Users/EditRole.vue')
    },
    {
        path: '/groups',
        name: 'groups',
        component: () => import('./components/Users/Groups.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('./components/Users/Users.vue'),
        children: []
    },
    {
        path: '/adduser',
        name: 'adduser',
        component: () => import('./components/Users/AddUser.vue'),
    },
    {
        path: '/edituser/:userId',
        name: 'edituser',
        component: () => import('./components/Users/EditUser.vue'),
    },
    // Existing menu item with not implemented vue components yet!
    // Not defined yet redirect to empty page!
    {
        path: '/invalid',
        name: 'invalid',
        component: () => import('./components/EmptyPage.vue'),
    },
    {
        path: '/menuProfile',
        name: 'menuProfile',
        component: () => import('./components/EmptyPage.vue'),
    }
    // CAIS SPECIFIC - END
];

const router = createRouter({
    history: createWebHistory(), // CAIS SPECIFIC changed from createWebHashHistory() to createWebHistory()
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;