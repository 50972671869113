<template>
    <div class='field col-12 lg:col-4 md:col-12 p-sm-12'>
        <label for='filter' class='font-bold'>{{ labelText }}</label>
        <div id='filter' class='grid col-12 p-inputgroup'>
            <Button @click='toggleButtonOperator' style='width: 90px'>
                <span class='p-button-label'>{{ filterOperator }}</span>
            </Button>
            <MultiSelect :placeholder="placeholderText+'...'" v-model="filterValue" :options='filterValueList'
                         :filter='false' optionLabel='value' optionValue='key' class='p-multiselect col-10'></MultiSelect>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    name: 'RoleFilter',
    emits: ['filterOperator','filterValue'],
    setup() {
        const store = useStore()

        return {
            token: computed(() => store.state.bearerToken),
        }
    },
    data() {
        return {
            filterOperator: 'NOT IN',
            filterOperatorList: ['IN', 'NOT IN'],
            filterValue: [],
            filterValueList: [],
        }
    },
    props: {
        labelText: {
            type: String,
            required: true
        },
        placeholderText: {
            type: String,
            required: true
        },
    },
    methods: {
        toggleButtonOperator() {
            let index = this.filterOperatorList.indexOf(this.filterOperator) + 1
            let newIndex = (index%(this.filterOperatorList.length))
            this.filterOperator = this.filterOperatorList[newIndex]
            this.$emit('filterOperator', this.filterOperator)
        },
        deleteValues() {
          this.filterOperator = 'NOT IN'
          this.filterValue = []
        },
    },
    watch: {
        filterValue:function() {
            this.$emit('filterValue', this.filterValue)
        }
    },
    created() {

    },
    mounted() {
        //get all roles for filters
        axios
            .get( '/app/webObjects/dropdown/ROLES/KEY-VALUE/false/get', {
                headers: {
                    Authorization: this.token
                }})
            .then( response => {
                if(response.status === 200 && response.data.rmsList[0].retCode === 0){
                    this.filterValueList = response.data.retObj.dropDownList
                }
            })
            .catch(error => {
                if(error){
                    console.log("There was an error loading role filter");
                }

            })
    }
};
</script>

<style scoped>

</style>