<template>
    <Toast></Toast>
    <ConfirmDialog></ConfirmDialog>
	<div :class="containerClass" @click="onDocumentClick">
		<AppTopBar :topbarMenuActive="topbarMenuActive" :activeTopbarItem="activeTopbarItem"
						@menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick"></AppTopBar>

        <transition name="layout-menu-container">
            <div class="layout-menu-container" @click="onMenuClick" v-show="isMenuVisible()">
                <div class="layout-menu-content">
                    <AppMenu :key="'appmsenu'" :model="menu" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive"
                            @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>

                    <div class="layout-menu-footer">
                        <div class="layout-menu-footer-title">TASKS</div>

                        <div class="layout-menu-footer-content">
                            <ProgressBar :value="50" :showValue="false"></ProgressBar>
                            Today
                            <ProgressBar :value="80" :showValue="false"></ProgressBar>
                            Overall
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div class="layout-content">
            <AppBreadcrumb></AppBreadcrumb>

            <div class="layout-content-container">
                <router-view />
            </div>

            <AppFooter />

            <div v-if="staticMenuMobileActive" class="layout-mask"></div>
        </div>

        <AppConfig :layoutMode="layoutMode" :theme="theme" :colorScheme="colorScheme" @layout-change="onLayoutChange" @theme-change="onThemeChange" @color-scheme-change="onChangeColorScheme"/>
	</div>
</template>
<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import AppFooter from './AppFooter.vue';
import AppConfig from './AppConfig.vue';
import EventBus from './event-bus';
// CAIS SPECIFIC - START
import { useStore } from 'vuex';
import { computed } from 'vue';
import axios from 'axios';
import { usePrimeVue } from 'primevue/config'; // Check if needed!
// CAIS SPECIFIC - END

export default {
	emits: ['color-scheme-change', 'theme-change'],
	props: {
		colorScheme: String,
		theme: String
	},
    // CAIS SPECIFIC - START
    setup() {
        const store = useStore()
        const primeVue = usePrimeVue()

        return {
            token: computed(() => store.state.bearerToken),
            userID: computed(() => store.state.userId),
            autoLoginUser: () => store.dispatch('tryAutoLogin'),
            t: primeVue.config.locale,
            //t: this.$primevue.config.locale,
            defLang: computed(() => store.state.defLang),
        }
    },
    created() {

    },
    mounted() {
        this.autoLoginUser();

        setTimeout( () => {
            const langDict = JSON.parse(localStorage.getItem('langDict'));

            for(let key in langDict) {
                this.$primevue.config.locale[key] = langDict[key]
            }
        }, 200)

        if(this.token) {
            axios
                .get( '/administration/menu/user/' + this.userID + '/getMenu/', {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then( response => {
                    if(response.data.rmsList[0].retCode === 0){
                        this.menu = response.data.retObj
                        this.menu[1].items[0].items[1].to = '/texts/'+this.defLang.defLang
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg});
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    },
    // CAIS SPECIFIC - END
	data() {
		return {
			layoutMode: 'static',
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			overlayMenuActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
            menuActive: false,
			menu : [] // Manu is filled automatically - CAIS SPECIFIC
		}
	},
	watch: {
		$route() {
			this.menuActive = false;
			this.$toast.removeAllGroups();
		}
	},
	methods: {
		onDocumentClick() {
			if (!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if (!this.menuClick) {
				if (this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
				}

                EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}

			this.topbarItemClick = false;
			this.menuClick = false;
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;
			this.topbarMenuActive = false;

			if (this.layoutMode === 'overlay' && !this.isMobile()) {
				this.overlayMenuActive = !this.overlayMenuActive;
			}
			else {
				if (this.isDesktop())
					this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
				else
					this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isMobile() {
			return window.innerWidth <= 640;
		},
		isHorizontal() {
			return this.layoutMode === 'horizontal';
		},
		isSlim() {
			return this.layoutMode === 'slim';
        },
		hideOverlayMenu() {
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
        isMenuVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuDesktopInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
        toggleConfigurator() {
            this.configuratorActive = !this.configuratorActive;
        },
        hideConfigurator() {
            this.configuratorActive = false;
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
		onThemeChange(theme) {
			this.$emit('theme-change', theme);
		},
		onChangeColorScheme(schemeColor) {
			this.$emit('color-scheme-change', schemeColor);
		}
	},
	computed: {
		containerClass() {
			return ['layout-wrapper', {
				'layout-horizontal': this.layoutMode === 'horizontal',
				'layout-overlay': this.layoutMode === 'overlay',
				'layout-static': this.layoutMode === 'static',
				'layout-slim': this.layoutMode === 'slim',
				'layout-static-inactive': this.staticMenuDesktopInactive,
				'layout-mobile-active': this.staticMenuMobileActive,
				'layout-overlay-active': this.overlayMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
        }
	},
	components: {
		'AppTopBar': AppTopBar,
		'AppMenu': AppMenu,
		'AppBreadcrumb': AppBreadcrumb,
        'AppFooter': AppFooter,
        'AppConfig': AppConfig
	}
}
</script>

<style lang="scss">
@import 'App.scss';
</style>
