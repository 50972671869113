<template>
    <Login v-if="$route.path === '/login'" />
    <Error v-else-if="$route.path === '/error'" />
    <Access v-else-if="$route.path === '/access'" />
    <NotFound v-else-if="$route.path === '/notfound'" />
    <!-- CAIS SPECIFICS - START -->
    <ForgotPassword v-else-if="$route.path === '/forgotPassword'"></ForgotPassword>
    <SetPassword v-else-if="$route.path === '/setPassword/:t'"></SetPassword>
    <!-- CAIS SPECIFICS - END -->
    <App v-else :theme="theme" :colorScheme="colorScheme" @theme-change="onThemeChange"  @color-scheme-change="onSchemeChange" />
</template>

<script>
    import EventBus from './event-bus';
    import App from './App.vue';
    import Error from './pages/Error';
    import Access from './pages/Access';
    import Login from './pages/Login';
    import NotFound from './pages/NotFound';
    // CAIS SPECIFICS - START
    import SetPassword from './pages/SetPassword';
    import ForgotPassword from './pages/ForgotPassword';
    // CAIS SPECIFICS - END

	export default {
        data() {
            return {
                colorScheme: 'light',
                theme: 'blue',
            }
        },
        methods: {
             onThemeChange(theme) {
                this.theme = theme.name;
                this.changeTheme(this.theme + '-' + this.colorScheme);
            },
            onSchemeChange(color) {
                this.$appState.colorScheme = color;
                const themeLink = document.getElementById('theme-css');
                const href = themeLink.href;
                const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
                const themeTokens = themeFile.split('-');
                const themeName = themeTokens[1];

                this.changeTheme(themeName + '-' + color);
            },
            changeTheme(theme) {
                this.theme = theme.split('-')[0];
                this.changeStyleSheetUrl('layout-css', theme, 'layout');
                this.changeStyleSheetUrl('theme-css', theme, 'theme');
            },
            changeStyleSheetUrl(id, value, prefix) {
                let element = document.getElementById(id);
                let urlTokens = element.getAttribute('href').split('/');
                urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
                let newURL = urlTokens.join('/');
                this.replaceLink(element, newURL);

                if (value.indexOf('dark') !== -1)
                    this.colorScheme = 'dark';
                else if(value.indexOf('dim') !== -1)
                    this.colorScheme = 'dim';
                else
                    this.colorScheme = 'light';

                EventBus.emit('on-scheme-change', this.colorScheme);
            },
            replaceLink(linkElement, href) {
                const id = linkElement.getAttribute('id');
                const cloneLinkElement = linkElement.cloneNode(true);

                cloneLinkElement.setAttribute('href', href);
                cloneLinkElement.setAttribute('id', id + '-clone');

                linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

                cloneLinkElement.addEventListener('load', () => {
                    linkElement.remove();
                    cloneLinkElement.setAttribute('id', id);
                });
            },
        },
        components: {
            "App": App,
            "Error": Error,
            "Access": Access,
            "Login": Login,
            "NotFound": NotFound,
            "SetPassword": SetPassword,
            "ForgotPassword": ForgotPassword
        }
	}
</script>

<style scoped>
</style>