<template>
    <div class="login-body">
        <div class="body-container">
            <div class="grid">
                <div class="col-12 lg:col-6 left-side">
                    <img src="layout/images/ca-logo.png" alt="apollo-layout" class="logo"/>
                    <h1>Forgot password?</h1>
                    <p>
                        Please enter your valid email. Message will be sent shortly after.
                    </p>
                </div>
                <div class="col-12 lg:col-6 right-side">
                    <div class="login-wrapper">
                        <div class="login-container">
                            <span class="title">Forgot password?</span>
                            <Toast />
                            <div class="grid p-fluid">
                                <div class="col-12 text-left">
                                    <InputText placeholder="Username" v-model="username" v-bind:class="{ 'p-error' : invalidEmail }" aria-describedby="username-help"/>
                                    <small id="username-help" class="p-error" v-if="invalidEmail">{{ invalidEmailMessage }}</small>
                                </div>
                                <div class="col-6">
                                    <Button label="Send" icon="pi pi-check" @click="verifyInputs"/>
                                </div>
                                <div class="col-6 password-container">
                                    <Button class="p-button-link" @click="$router.push('/setPassword')">Reset Password</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "ForgotPassword",
    data() {
        return {
            username: '',
            validInputs: true,
            invalidEmail: false,
            invalidEmailMessage: "",
            message: []
        }
    },
    methods: {
        verifyInputs() {
            this.message = []
            this.validInputs = true
            if(!this.username) {
                this.invalidEmailMessage = "Username cannot be empty."
                this.invalidEmail = true
                this.validInputs = false
            } else {
                ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]/;
                if(!regex.test(this.username.toLowerCase())){
                    this.invalidEmailMessage = "Invalid email format."
                    this.invalidEmail = true
                    this.validInputs = false
                } else {
                    this.invalidEmail = false
                }
            }
            if(this.validInputs) {
                this.sendEmail()
            }
        },
        sendEmail() {
            axios
                .post( '/api/public/user/resetpassword', {
                    'email': this.username
                })
                .then( response => {
                    //console.log(response.data.retObj);
                    if(response.data.rmsList[0].retCode === 420){
                        this.$toast.add({severity:'success', summary: 'Success', detail:response.data.rmsList[0].retMsg});
                        setTimeout(() => {
                            this.code = "username"
                            this.$router.push('/setPassword')
                        }, 5000)
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg});
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }
}
</script>

<style scoped>

</style>