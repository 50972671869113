<template>
    <div :class="containerClass">
        <div class="layout-config-content">
            <a href="#" class="layout-config-button" @click="toggleConfigurator">
                <i class="pi pi-cog"></i>
            </a>
            <a href="#" class="layout-config-close" @click="hideConfigurator">
                <i class="pi pi-times"></i>
            </a>

            <h5>Color Mode</h5>
            <div class="field-radiobutton">
                <RadioButton id="dark" name="color" value="dark" v-model="d_colorScheme" @change="onSchemeChange($event, 'dark')" />
                <label for="dark">Dark</label>
            </div>
            <div class="field-radiobutton">
                <RadioButton id="dim" name="color" value="dim" v-model="d_colorScheme" @change="onSchemeChange($event, 'dim')" />
                <label for="dim">Dim</label>
            </div>
            <div class="field-radiobutton">
                <RadioButton id="light" name="color" value="light" v-model="d_colorScheme" @change="onSchemeChange($event, 'light')" />
                <label for="light">Light</label>
            </div>

            <h5>Menu Mode</h5>
            <div class="field-radiobutton">
                <RadioButton id="static" name="layoutMode" value="static" v-model="d_layoutMode" @change="changeLayout($event, 'static')" />
                <label for="static">Static</label>
            </div>
            <div class="field-radiobutton">
                <RadioButton id="overlay" name="layoutMode" value="overlay" v-model="d_layoutMode" @change="changeLayout($event, 'overlay')" />
                <label for="overlay">Overlay</label>
            </div>
            <div class="field-radiobutton">
                <RadioButton id="horizontal" name="layoutMode" value="horizontal" v-model="d_layoutMode" @change="changeLayout($event, 'horizontal')" />
                <label for="horizontal">Horizontal</label>
            </div>
            <div class="field-radiobutton">
                <RadioButton id="slim" name="layoutMode" value="slim" v-model="d_layoutMode" @change="changeLayout($event, 'slim')" />
                <label for="slim">Slim</label>
            </div>

            <h5 style="margin-top: 0">Input Style</h5>
            <div class="formgroup-inline">
                <div class="field-radiobutton">
                    <RadioButton id="input_outlined" name="inputstyle" value="outlined" :modelValue="value" @update:modelValue="onChange" />
                    <label for="input_outlined">Outlined</label>
                </div>
                <div class="field-radiobutton">
                    <RadioButton id="input_filled" name="inputstyle" value="filled" :modelValue="value" @update:modelValue="onChange" />
                    <label for="input_filled">Filled</label>
                </div>
            </div>

            <h5>Ripple Effect</h5>
            <InputSwitch :modelValue="rippleActive" @update:modelValue="onRippleChange"  />

            <h5>Component Themes</h5>
            <div class="layout-themes">
                <div v-for="t of themes" :key="t.name">
                    <a href="#" @click="onThemeChange($event, t)" :style="{backgroundColor:t.color}">
                        <i class="pi pi-check" v-if="theme === t.name"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['layout-change', 'color-scheme-change', 'theme-change'],
    props: {
        layoutMode: {
            type: String,
            default: null
        },
        colorScheme: String,
        theme: String
    },
    data() {
        return {
            active: false,
            themes: [
                {title: 'Blue', name: 'blue', color: '#39a3f4'},
                {title: 'Green', name: 'green', color: '#6ebc3b'},
                {title: 'Cyan', name: 'cyan', color: '#1989AC'},
                {title: 'Purple', name: 'purple', color: '#7E57C2'},
                {title: 'Indigo', name: 'indigo', color: '#5C6BC0'},
                {title: 'Yellow', name: 'yellow', color: '#ffc800'},
                {title: 'Orange', name: 'orange', color: '#f6a821'},
                {title: 'Pink', name: 'pink', color: '#EC407A'}
            ],
            d_layoutMode: this.layoutMode,
            d_colorScheme: this.colorScheme
        }
    },
    watch: {
        $route() {
            if (this.active) {
                this.active = false;
                this.unbindOutsideClickListener();
            }
        },
        layoutMode(newValue) {
            this.d_layoutMode = newValue;
        },
        colorScheme(newValue) {
            this.d_colorScheme = newValue;
        }
    },
    outsideClickListener: null,
    methods: {
        onChange(value) {
            this.$primevue.config.inputStyle = value;
        },
        onRippleChange(value) {
            this.$primevue.config.ripple = value;
        },
        changeLayout(event, layoutMode) {
            this.$emit('layout-change', layoutMode);
            event.preventDefault();
        },
        onSchemeChange(event, color) {
            this.$emit('color-scheme-change', color);
            event.preventDefault();
        },
        onThemeChange(event,theme) {
            this.$emit('theme-change', theme);
            event.preventDefault();
        },
        toggleConfigurator(event) {
            this.active = !this.active;
            event.preventDefault();

            if (this.active)
                this.bindOutsideClickListener();
            else
                this.unbindOutsideClickListener();
        },
        hideConfigurator(event) {
            this.active = false;
            this.unbindOutsideClickListener();
            event.preventDefault();
        },
        bindOutsideClickListener() {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    if (this.active && this.isOutsideClicked(event)) {
                        this.active = false;
                    }
                };
                document.addEventListener('click', this.outsideClickListener);
            }
        },
        unbindOutsideClickListener() {
            if (this.outsideClickListener) {
                document.removeEventListener('click', this.outsideClickListener);
                this.outsideClickListener = null;
            }
        },
        isOutsideClicked(event) {
            return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
        },
    },
    computed: {
        containerClass() {
            return ['layout-config', {'layout-config-active': this.active}];
        },
        rippleActive() {
            return this.$primevue.config.ripple;
        },
        value() {
            return this.$primevue.config.inputStyle;
        }
    }
}
</script>
