<template>
	<div class="layout-breadcrumb">
		<ul>
			<li><button class="p-link" @click="home"><i class="pi pi-home"></i></button></li>
            <li>{{$route.path}}</li>
            <!--
            <li v-if="$route.path === '/'">/</li>
			<template v-if="$route.meta.breadcrumb">
				<li>/</li>
				<li>{{$route.meta.breadcrumb[0].parent}}</li>
				<li>/</li>
				<li>{{$route.meta.breadcrumb[0].label}}</li>
			</template>
            -->
		</ul>

        <!--
		<div class="layout-breadcrumb-options">
			<button class="p-link" title="Backup" @click="home">
				<i class="pi pi-cloud-upload"></i>
			</button>
			<button class="p-link" title="Bookmark" @click="home">
				<i class="pi pi-bookmark"></i>
			</button>
			<button class="p-link" title="Logout" @click="home">
				<i class="pi pi-power-off"></i>
			</button>
		</div>
		-->
	</div>
</template>

<script>
	export default {
		methods: {
			home() {
                this.$router.push({ path: '/' });
			}
		}
	}
</script>

<style scoped>

</style>