<template>
    <div class='field col-12 lg:col-4 md:col-12 p-sm-12'>
        <label for='filter' class='font-bold'>{{ labelText }}</label>
        <div id='filter' class='grid col-12 p-inputgroup'>
          <Button @click='toggleButtonOperator' style='width: 90px'>
            <span class='p-button-label'>{{ filterOperator }}</span>
          </Button>
          <InputText :placeholder="placeholderText+'...'" class='col-12' v-model="filterValue" @input='onInput'></InputText>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UidFilter',
    emits: ['filterOperator','filterValue'],
    setup() {
        return {

        }
    },
    data() {
        return {
            filterOperator: '=',
            filterOperatorList: ['>', '>=', '<', '<=', '='],
            filterValue: '',
        }
    },
    props: {
        labelText: {
            type: String,
            required: true
        },
        placeholderText: {
            type: String,
            required: true
        },
    },
    methods: {
      toggleButtonOperator() {
          let index = this.filterOperatorList.indexOf(this.filterOperator) + 1
          let newIndex = (index%(this.filterOperatorList.length))
          this.filterOperator = this.filterOperatorList[newIndex]
          this.$emit('filterOperator', this.filterOperator)
      },
      onInput() {
        this.$emit('filterValue', this.filterValue);
      },
      deleteValues() {
        this.filterOperator = '='
        this.filterValue = ''
      },
    },
    watch: {

    },
    created() {

    },
    mounted() {

    }
};
</script>

<style scoped>

</style>