<template>
	<div class="login-body">
		<div class="body-container">
			<div class="grid">
				<div class="col-12 lg:col-6 left-side">
					<img :src="'layout/images/logo-' + logoColor + '.png'" alt="apollo-layout"/>
					<h1>//Welcome</h1>
					<p>
						//Sign in to start your session
					</p>
				</div>
				<div class="col-12 lg:col-6 right-side">
					<div class="login-wrapper">
						<div class="login-container">
							<span class="title">Login</span>

                            <transition-group name="p-messages" tag="div">
                                <Message v-for="msg of message" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
                            </transition-group>

							<div class="grid p-fluid">
								<div class="col-12 text-left">
									<InputText placeholder="Username" v-model="username" v-bind:class="{ 'p-error' : invalidEmail }" aria-describedby="username-help"/>
                                    <small id="username-help" class="p-error" v-if="invalidEmail">{{ invalidEmailMessage }}</small>
								</div>
								<div class="col-12 text-left">
									<InputText type="password" placeholder="Password" v-model="password" v-bind:class="{ 'p-error' : invalidPassword }" aria-describedby="password-help" @keyup.enter='verifyInputs'/>
                                    <small id="password-help" class="p-error" v-if="invalidPassword">{{ invalidPasswordMessage }}</small>
								</div>
								<div class="col-6">
										<Button label="Log in" icon="pi pi-check" @click="verifyInputs"/>
								</div>
								<div class="col-6 password-container">
									<button class="p-link" @click="$router.push('/forgotPassword')">//Forgot Password?</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

	export default {
        setup() {
            const store = useStore()

            return {
                token: computed(() => store.state.bearerToken)
            }
        },
        data() {
            return {
                username: null,
                password: null,
                validInputs: true,
                invalidEmail: false,
                invalidEmailMessage: "",
                invalidPassword: false,
                invalidPasswordMessage: "",
                message: []
            }
        },
        mounted() {
        },
		computed: {
			logoColor() {
				if (this.$appState.colorScheme === 'light')
					return 'dark';
				else return 'white';
			}
		},
        methods: {
            verifyInputs() {
                this.message = []
                this.validInputs = true
                if(!this.username) {
                    this.invalidEmailMessage = "Username cannot be empty."
                    this.invalidEmail = true
                    this.validInputs = false
                } else {
                    ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]/;
                    if(!regex.test(this.username.toLowerCase())){
                        this.invalidEmailMessage = "Invalid email format."
                        this.invalidEmail = true
                        this.validInputs = false
                    } else {
                        this.invalidEmail = false
                    }
                }
                if(!this.password) {
                    this.invalidPasswordMessage = "Password cannot be empty."
                    this.invalidPassword = true
                    this.validInputs = false
                } else {
                    this.invalidPassword = false
                }
                if(this.validInputs) {
                    this.login()
                }
            },
            login() {
                this.$store.dispatch('login', {username: this.username, password: this.password})

                setTimeout(() => {
                    if(!this.token) {
                        this.message = [{severity: 'error', content: 'Invalid credentials', life: 500}]
                    }
                    this.username = ''
                    this.password = ''
                }, 1000)
            },
        }
	}
</script>

<style scoped>

</style>