<template>
    <div class="field col-12 lg:col-4 md:col-12 p-sm-12">
        <label for="filter" class="font-bold">{{ filterLabel }}</label>
        <div id="filter" class="grid col-12">
            <div class="p-inputgroup">
                <Button @click="toggleFilterButton" style="width: 90px">
                    <span class="p-button-label">{{ dataButtonOperator }}</span>
                </Button>
                <InputText v-if="filterType===1" v-model="dataInputValue" :placeholder="placeholderText"></InputText>
                <MultiSelect v-if="filterType===2" v-model="dataInputValue" :options="inputValuesList" optionLabel="value" optionValue="key"
                             :placeholder="placeholderText" :filter="false" class="multiselect-custom col-10" >
                </MultiSelect>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterInput',
    data() {
        return {

        }
    },
    props: {
        filterType: Number,
        filterLabel: String,
        placeholderText:String,
        buttonOperator: String,
        buttonOperatorsList: Array,
        inputValue: null,
        inputValuesList: Array,
    },
    methods: {
        toggleFilterButton() {
            let index = this.dataButtonOperatorsList.indexOf(this.dataButtonOperator) + 1
            let newIndex = (index%(this.dataButtonOperatorsList.length))
            this.dataButtonOperator = this.dataButtonOperatorsList[newIndex]
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        dataButtonOperator: {
            get: function () {
                return this.buttonOperator
            },
            set: function (newValue) {
                //this.buttonOperator = newValue
                this.$emit('update:buttonOperator', newValue)
            }
        },
        dataInputValue: {
            get: function () {
                return this.inputValue
            },
            set: function (newValue) {
                this.$emit('update:inputValue', newValue)
            }
        },
        dataButtonOperatorsList() {
            return this.buttonOperatorsList
        }
    },
    watch: {

    }
};
</script>

<style scoped>

</style>