<template>
	<div class="topbar clearfix">
		<router-link to="/" class="logo-link">
            <img class="logo" alt="apollo-layout" src="layout/images/apollo_logo.png" />
        </router-link>
        
        <button class="p-link menu-button" @click="onMenuButtonClick">
			<i class="pi pi-align-left"></i>
		</button>

		<button class="p-link profile" @click="onTopbarMenuButtonClick">
			<span class="username">{{ username }}</span>
            <span class="username font-light">- {{ defaultrole }}</span>
            <img src="layout/images/avatar/avatar.png" alt="apollo-layout" />
			<i class="pi pi-angle-down"></i>
		</button>

        <!--
		<span class="topbar-search">
			<InputText placeholder="Search"/>
			<span class="pi pi-search"></span>
		</span>
		-->

		<ul :class="topbarItemsClass" role="menu">
			<li :class="[{'menuitem-active': activeTopbarItem === 'profile'}]"
				@click="$emit('topbar-item-click', {originalEvent:$event,item:'profile'})" role="none">
				<button class="p-link">
					<i class="topbar-icon pi pi-fw pi-user"></i>
					<span class="topbar-item-name">Profile</span>
				</button>

                <transition name="layout-submenu-container">
                    <ul v-show="activeTopbarItem === 'profile'">
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-user-edit"></i>
                                <span>Account</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-eye"></i>
                                <span>Privacy</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-cog"></i>
                                <span>Settings</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-power-off"></i>
                                <span>Logout</span>
                            </button>
                        </li>
                    </ul>
                </transition>
			</li>


			<li :class="[{'menuitem-active': activeTopbarItem === 'settings'}]">
				<button class="p-link" @click="$emit('topbar-item-click', {originalEvent:$event,item:'settings'})">
					<i class="topbar-icon pi pi-fw pi-cog"></i>
					<span class="topbar-item-name">Settings</span>
				</button>
                <transition name="layout-submenu-container">
                    <ul v-show="activeTopbarItem === 'settings'">
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-palette"></i>
                                <span>Change Theme</span>
                                <span class="topbar-badge">1</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-bookmark"></i>
                                <span>Favorites</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-lock"></i>
                                <span>Lock Screen</span>
                                <span class="topbar-badge">3</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-image"></i>
                                <span>Wallpaper</span>
                            </button>
                        </li>
                    </ul>
                </transition>
			</li>

			<li :class="[{'menuitem-active': activeTopbarItem === 'messages'}]">
				<button class="p-link" @click="$emit('topbar-item-click', {originalEvent:$event,item:'messages'})">
					<i class="topbar-icon pi pi-fw pi-envelope"></i>
					<span class="topbar-item-name">Messages</span>
					<span class="topbar-badge">5</span>
				</button>
                <transition name="layout-submenu-container">
                    <ul v-show="activeTopbarItem === 'messages'">
                        <li role="none">
                            <button class="p-link topbar-message" role="menuitem">
                                <img alt="Avatar 1" src="layout/images/avatar/avatar1.png" width="35"/>
                                <span>Give me a call</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link topbar-message" role="menuitem">
                                <img alt="Avatar 2" src="layout/images/avatar/avatar2.png" width="35"/>
                                <span>Sales reports attached</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link topbar-message" role="menuitem">
                                <img alt="Avatar 3" src="layout/images/avatar/avatar3.png" width="35"/>
                                <span>About your invoice</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link topbar-message" role="menuitem">
                                <img alt="Avatar 4" src="layout/images/avatar/avatar2.png" width="35"/>
                                <span>Meeting today at 10pm</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link topbar-message"  role="menuitem">
                                <img alt="Avatar 5" src="layout/images/avatar/avatar4.png" width="35"/>
                                <span>Out of office</span>
                            </button>
                        </li>
                    </ul>
                </transition>
			</li>

			<li :class="[{'menuitem-active': activeTopbarItem === 'notifications'}]">
				<button class="p-link" @click="$emit('topbar-item-click', {originalEvent:$event,item:'notifications'})">
					<i class="topbar-icon pi pi-fw pi-bell"></i>
					<span class="topbar-item-name">Notifications</span>
					<span class="topbar-badge">2</span>
				</button>
                <transition name="layout-submenu-container">
                    <ul v-show="activeTopbarItem === 'notifications'">
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-list"></i>
                                <span>Pending tasks</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-calendar"></i>
                                <span>Meeting today</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-download"></i>
                                <span>Download documents</span>
                            </button>
                        </li>
                        <li role="none">
                            <button class="p-link" role="menuitem">
                                <i class="pi pi-fw pi-ticket"></i>
                                <span>Book flight</span>
                            </button>
                        </li>
                    </ul>
                </transition>
			</li>
            <li>
                <button class="p-link" @click="logout">
                    <i class="topbar-icon pi pi-fw pi-power-off"></i>
                    <span class="topbar-item-name">Logout</span>
                </button>
            </li>
		</ul>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import axios from 'axios';

export default {
    data() {
        return {
            username: "",
            defaultrole: ""
        }
    },
    setup() {
        const store = useStore()

        return {
            token: computed(() => store.state.bearerToken),
            userID: computed(() => store.state.userId),
            autoLoginUser: () => store.dispatch('tryAutoLogin'),
            logoutUser: () => store.dispatch('logout')
        }
    },
    mounted() {
      setTimeout(() => {
        if(this.token) {
        axios
            .get('administration/user/' + this.userID + '/getData', {
              headers: {
                'Authorization': this.token
              }
            })
            .then(response => {
              if (response.status === 200 && response.data.rmsList[0].retCode === 0) {
                if (response.data.retObj.fname) {
                  this.username = response.data.retObj.fname + ' '
                }
                if (response.data.retObj.mname) {
                  this.username += response.data.retObj.mname + ' '
                }
                if (response.data.retObj.lname) {
                  this.username += response.data.retObj.lname
                }
                this.defaultrole = response.data.retObj.drolet
                this.$primevue.config.locale['firstDayOfWeek'] = response.data.retObj.uSettings.firstDayOfWeek
              } else {
                this.username = 'Unknown Name';
                this.defaultrole = 'No Role';
              }
            })
            .catch(error => {
              console.log("There was an error loading users list!");
              console.log(error);
            })
        }
      }, 250)
    },
    emits: ['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click', ],
	props: {
		topbarMenuActive: Boolean,
		activeTopbarItem: String
	},
    methods: {
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
		onTopbarMenuButtonClick(event){
			this.$emit('topbar-menubutton-click', event);
		},
        logout() {
            this.logoutUser()
        }
    },
	computed: {
		topbarItemsClass() {
			return ['topbar-menu fadeInDown', {
				'topbar-menu-visible': this.topbarMenuActive
			}];
		}
	}
}
</script>
